import React from 'react'
import SvgIcon, { type SvgIconProps } from '~/components/SvgIcon'

const Download = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path d="M2 15V13.5H14V15H2ZM8.53125 10.7812L8 11.3438L7.4375 10.7812L3.4375 6.53125L2.9375 5.96875L4.03125 4.96875L4.53125 5.5L7.25 8.375V1H8.75V8.375L11.4375 5.5L11.9688 4.96875L13.0625 5.96875L12.5312 6.53125L8.53125 10.7812Z" />
    </SvgIcon>
  )
}

export default Download
